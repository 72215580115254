var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-wrap departure-situation" },
    [
      _c("div", { staticClass: "form-wrap" }, [
        _c("div", { staticClass: "seach" }, [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("div", { staticClass: "divname" }, [_vm._v("城市仓：")]),
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择城市仓",
                  },
                  on: { change: _vm.onChangeCityStore },
                  model: {
                    value: _vm.formData.logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "logistics_id", $$v)
                    },
                    expression: "formData.logistics_id",
                  },
                },
                _vm._l(_vm.cityStoreList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("div", { staticClass: "divname" }, [_vm._v("自提点：")]),
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    "filter-method": (val) =>
                      _vm.onFilterMethod("selfTake", val),
                    placeholder: "选择自提点",
                  },
                  on: {
                    "visible-change": (val) =>
                      _vm.onChangeVisible("selfTake", val),
                  },
                  model: {
                    value: _vm.formData.delivery_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "delivery_id", $$v)
                    },
                    expression: "formData.delivery_id",
                  },
                },
                _vm._l(_vm.selfTakeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "车型" },
                  model: {
                    value: _vm.formData.vehicle_model,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "vehicle_model", $$v)
                    },
                    expression: "formData.vehicle_model",
                  },
                },
                _vm._l(_vm.VEHICLE_MODEL, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "是否合车" },
                  model: {
                    value: _vm.formData.is_merge,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_merge", $$v)
                    },
                    expression: "formData.is_merge",
                  },
                },
                _vm._l(_vm.whetheroption, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "物流状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "未开仓", value: -1 } }),
                  _c("el-option", { attrs: { label: "未进场", value: 0 } }),
                  _c("el-option", { attrs: { label: "未发车", value: 1 } }),
                  _c("el-option", { attrs: { label: "未到达", value: 2 } }),
                  _c("el-option", { attrs: { label: "已到达", value: 3 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "车牌号" },
                model: {
                  value: _vm.formData.license_plates_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "license_plates_no", $$v)
                  },
                  expression: "formData.license_plates_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "车类别" },
                  model: {
                    value: _vm.formData.vehicle_model_type_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "vehicle_model_type_name", $$v)
                    },
                    expression: "formData.vehicle_model_type_name",
                  },
                },
                _vm._l(_vm.carModelType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "是否平台调车" },
                  model: {
                    value: _vm.formData.is_platform_dispatch,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_platform_dispatch", $$v)
                    },
                    expression: "formData.is_platform_dispatch",
                  },
                },
                _vm._l(_vm.whetheroption, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "是否平台装卸" },
                  model: {
                    value: _vm.formData.is_platform_fleet,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_platform_fleet", $$v)
                    },
                    expression: "formData.is_platform_fleet",
                  },
                },
                _vm._l(_vm.whetheroption, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "城市仓是否分货完成" },
                  model: {
                    value: _vm.formData.is_finish,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_finish", $$v)
                    },
                    expression: "formData.is_finish",
                  },
                },
                _vm._l(_vm.whetheroption, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("div", { staticClass: "divname" }, [_vm._v("供货日期：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "供货时间始",
                  "end-placeholder": "供货时间止",
                },
                model: {
                  value: _vm.cycle,
                  callback: function ($$v) {
                    _vm.cycle = $$v
                  },
                  expression: "cycle",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("div", { staticClass: "divname" }, [_vm._v("进场时间：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "进场时间始",
                  "end-placeholder": "进场时间止",
                },
                model: {
                  value: _vm.confirm_entry,
                  callback: function ($$v) {
                    _vm.confirm_entry = $$v
                  },
                  expression: "confirm_entry",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("div", { staticClass: "divname" }, [_vm._v("发车时间：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "发车时间始",
                  "end-placeholder": "发车时间止",
                },
                model: {
                  value: _vm.confirm_depart,
                  callback: function ($$v) {
                    _vm.confirm_depart = $$v
                  },
                  expression: "confirm_depart",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("div", { staticClass: "divname" }, [_vm._v("到达时间：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "到达时间始",
                  "end-placeholder": "到达时间止",
                },
                model: {
                  value: _vm.confirm_arrive,
                  callback: function ($$v) {
                    _vm.confirm_arrive = $$v
                  },
                  expression: "confirm_arrive",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-button", {
                staticClass: "refresh",
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.onHandleRefresh },
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    disabled: !_vm.tableData.length,
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.exportHandle },
                },
                [_vm._v("导 出")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.tableData.length || _vm.disabledExport,
                    type: "danger",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.onHandleExport },
                },
                [_vm._v("导出车型")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100px",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics_name",
                  align: "center",
                  label: "城市仓",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_name",
                  align: "center",
                  label: "自提点",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "is_merge", align: "center", label: "是否合车" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.is_merge ? "是" : "否") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "fleet_name", align: "center", label: "队别" },
              }),
              _c("el-table-column", {
                attrs: { prop: "car_list", align: "center", label: "车型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._l(
                          scope.row.car_list.vehicle_model_name_list,
                          function (child, index) {
                            return _c("div", { key: index }, [
                              _vm._v(" " + _vm._s(child) + " "),
                            ])
                          }
                        ),
                        !scope.row.car_list.vehicle_model_name_list.length
                          ? _c("div", [_vm._v(" - ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "car_list", align: "center", label: "车类别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._l(
                          scope.row.car_list.vehicle_model_type_name_list,
                          function (child, index) {
                            return _c("div", { key: index }, [
                              _vm._v(" " + _vm._s(child) + " "),
                            ])
                          }
                        ),
                        !scope.row.car_list.vehicle_model_type_name_list.length
                          ? _c("div", [_vm._v(" - ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "car_list",
                  align: "center",
                  label: "车牌(车头)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._l(
                          scope.row.car_list.front_license_plates_no_list,
                          function (child, index) {
                            return _c("div", { key: index }, [
                              _vm._v(" " + _vm._s(child) + " "),
                            ])
                          }
                        ),
                        !scope.row.car_list.front_license_plates_no_list.length
                          ? _c("div", [_vm._v(" - ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "car_list",
                  align: "center",
                  label: "车牌(车尾)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._l(
                          scope.row.car_list.after_license_plates_no_list,
                          function (child, index) {
                            return _c("div", { key: index }, [
                              _vm._v(" " + _vm._s(child) + " "),
                            ])
                          }
                        ),
                        !scope.row.car_list.after_license_plates_no_list.length
                          ? _c("div", [_vm._v(" - ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_platform_fleet",
                  align: "center",
                  label: "是否平台装卸",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.is_platform_fleet ? "是" : "否") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_platform_dispatch",
                  align: "center",
                  label: "是否平台调车",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.is_platform_dispatch ? "是" : "否"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "cycle", align: "center", label: "供货日期" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "confirm_entry_at",
                  align: "center",
                  label: "进场时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.confirm_entry_at || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "confirm_depart_at",
                  align: "center",
                  label: "发车时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.confirm_depart_at || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "confirm_arrive_at",
                  align: "center",
                  label: "到达时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.confirm_arrive_at || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "notes",
                  align: "center",
                  label: "备注",
                  "show-overflow-tooltip": "",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.notes || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  align: "center",
                  label: "物流状态",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == -1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                },
                              },
                              [_vm._v("未开仓")]
                            )
                          : _vm._e(),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                },
                              },
                              [_vm._v("未进场")]
                            )
                          : scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                },
                              },
                              [_vm._v("未发车")]
                            )
                          : scope.row.status == 2
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                },
                              },
                              [_vm._v("未到达")]
                            )
                          : scope.row.status == 3
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  plain: "",
                                },
                              },
                              [_vm._v("已到达")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_finish",
                  align: "center",
                  label: "城市仓是否分货完成",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.is_finish ? "是" : "否") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "qc_status",
                  align: "center",
                  label: "发车情况",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleView(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.personVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.personVisible,
                title: _vm.personTitle,
                "append-to-body": true,
              },
              on: {
                close: function ($event) {
                  _vm.personVisible = false
                },
                "update:visible": function ($event) {
                  _vm.personVisible = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.personVisible = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.personSure },
                          },
                          [_vm._v("保存")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4039927307
              ),
            },
            [
              _c(
                "div",
                {
                  staticClass: "car-dialog-wrap",
                  staticStyle: { width: "80%" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm from-wraps",
                      attrs: {
                        model: _vm.personForm,
                        rules: _vm.formRules,
                        "label-width": "20%",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "城市仓" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入城市仓",
                            },
                            model: {
                              value: _vm.personForm.logistics_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "logistics_name", $$v)
                              },
                              expression: "personForm.logistics_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "自提点" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入自提点",
                            },
                            model: {
                              value: _vm.personForm.delivery_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "delivery_name", $$v)
                              },
                              expression: "personForm.delivery_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否合车", prop: "is_merge_car" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "是否合车" },
                              model: {
                                value: _vm.personForm.is_merge_car,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "is_merge_car", $$v)
                                },
                                expression: "personForm.is_merge_car",
                              },
                            },
                            _vm._l(_vm.whetheroption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.personForm.is_merge_car == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择城市仓",
                                prop: "logistics_ids",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    clearable: "",
                                    placeholder: "选择城市仓",
                                  },
                                  on: { change: _vm.onChangeCityStoreDialog },
                                  model: {
                                    value: _vm.personForm.logistics_ids,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.personForm,
                                        "logistics_ids",
                                        $$v
                                      )
                                    },
                                    expression: "personForm.logistics_ids",
                                  },
                                },
                                _vm._l(_vm.cityStoreList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      disabled:
                                        item.id == _vm.personForm.logistics_id,
                                      label: item.name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.personForm.is_merge_car === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择自提点",
                                prop: "delivery_ids",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    clearable: "",
                                    placeholder: "选择自提点",
                                  },
                                  on: { change: _vm.onChangeDeliveryDialog },
                                  model: {
                                    value: _vm.personForm.delivery_ids,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.personForm,
                                        "delivery_ids",
                                        $$v
                                      )
                                    },
                                    expression: "personForm.delivery_ids",
                                  },
                                },
                                _vm._l(
                                  _vm.dialogDeliveryList,
                                  function (child) {
                                    return _c("el-option", {
                                      key: child.id,
                                      attrs: {
                                        label: child.name,
                                        value: child.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "队别", prop: "fleet_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择队别" },
                              on: { change: _vm.changeFleet },
                              model: {
                                value: _vm.personForm.fleet_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "fleet_id", $$v)
                                },
                                expression: "personForm.fleet_id",
                              },
                            },
                            _vm._l(_vm.FleetList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.fleet_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "car-list-wrap" },
                        _vm._l(
                          _vm.personForm.car_list,
                          function (child, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "car-child" },
                              [
                                _c("div", { staticClass: "handle" }, [
                                  index ===
                                    _vm.personForm.car_list.length - 1 &&
                                  _vm.personForm.car_list.length <
                                    _vm.CAR_MAX_LENGTH
                                    ? _c("i", {
                                        staticClass: "icon el-icon-plus",
                                        on: { click: _vm.onAddCarData },
                                      })
                                    : _vm._e(),
                                  _vm.personForm.car_list.length > 1
                                    ? _c("i", {
                                        staticClass: "icon el-icon-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDeleteCar(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "车型", prop: "car_list" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "选择车型",
                                        },
                                        model: {
                                          value: child.vehicle_model_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              child,
                                              "vehicle_model_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "child.vehicle_model_name",
                                        },
                                      },
                                      _vm._l(
                                        _vm.VEHICLE_MODEL,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "车类别",
                                      prop: "car_list",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "选择车类别",
                                        },
                                        model: {
                                          value: child.vehicle_model_type_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              child,
                                              "vehicle_model_type_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "child.vehicle_model_type_name",
                                        },
                                      },
                                      _vm._l(
                                        _vm.carModelType,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "车牌(车头)",
                                      prop: "car_list",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "车牌(车头)",
                                      },
                                      model: {
                                        value: child.front_license_plates_no,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            child,
                                            "front_license_plates_no",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "child.front_license_plates_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "车牌(车尾)",
                                      prop: "car_list",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "车牌(车尾)",
                                      },
                                      model: {
                                        value: child.after_license_plates_no,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            child,
                                            "after_license_plates_no",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "child.after_license_plates_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用板数量", prop: "use_plate_num" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "输入用板数量",
                            },
                            model: {
                              value: _vm.personForm.use_plate_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "use_plate_num", $$v)
                              },
                              expression: "personForm.use_plate_num",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否平台装卸",
                            prop: "is_platform_fleet",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "是否平台装卸",
                              },
                              model: {
                                value: _vm.personForm.is_platform_fleet,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.personForm,
                                    "is_platform_fleet",
                                    $$v
                                  )
                                },
                                expression: "personForm.is_platform_fleet",
                              },
                            },
                            _vm._l(_vm.whetheroption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否平台调车",
                            prop: "is_platform_dispatch",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "是否平台调车",
                              },
                              model: {
                                value: _vm.personForm.is_platform_dispatch,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.personForm,
                                    "is_platform_dispatch",
                                    $$v
                                  )
                                },
                                expression: "personForm.is_platform_dispatch",
                              },
                            },
                            _vm._l(_vm.whetheroption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "物流状态", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.disabledStatus,
                                clearable: "",
                                placeholder: "物流状态",
                              },
                              model: {
                                value: _vm.personForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "status", $$v)
                                },
                                expression: "personForm.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "未开仓",
                                  value: -1,
                                  disabled: _vm.disabledStatus,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "未进场",
                                  value: 0,
                                  disabled: _vm.disabledStatus,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "未发车",
                                  value: 1,
                                  disabled: _vm.disabledStatus,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "未到达",
                                  value: 2,
                                  disabled: _vm.disabledStatus,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "已到达",
                                  value: 3,
                                  disabled: _vm.disabledStatus,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.seepersonVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.seepersonVisible,
                title: "发车情况",
                "append-to-body": true,
              },
              on: {
                close: function ($event) {
                  _vm.seepersonVisible = false
                },
                "update:visible": function ($event) {
                  _vm.seepersonVisible = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.seepersonVisible = false
                              },
                            },
                          },
                          [_vm._v("关闭")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2036036133
              ),
            },
            [
              _c("div", [
                _c("div", { staticClass: "imglist" }, [
                  _c("div", { staticClass: "imglist-left" }, [
                    _vm._v("发车图片："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "imglist-right" },
                    _vm._l(_vm.srcList, function (item, index) {
                      return _c("el-image", {
                        key: index,
                        staticClass: "imgs",
                        attrs: {
                          src: item,
                          fit: "cover ",
                          "initial-index": index,
                          "preview-src-list": _vm.srcList,
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("div", { staticClass: "imglist" }, [
                  _c("div", { staticClass: "imglist-left" }, [
                    _vm._v("发车视频："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "imglist-right" },
                    _vm._l(_vm.videolist, function (item, index) {
                      return _c("video", {
                        key: index,
                        ref: "videoPlayer",
                        refInFor: true,
                        staticClass: "video pointer",
                        attrs: { src: item, autoplay: false, controls: true },
                      })
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }