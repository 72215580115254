<template>
  <div class="page-wrap departure-situation">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <div class="divname">城市仓：</div>
          <el-select
            filterable
            v-model="formData.logistics_id"
            clearable
            placeholder="选择城市仓"
            @change="onChangeCityStore"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">自提点：</div>
          <el-select
            filterable
            v-model="formData.delivery_id"
            clearable
            :filter-method="(val) => onFilterMethod('selfTake', val)"
            @visible-change="(val) => onChangeVisible('selfTake', val)"
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputs">
          <el-select
            v-model="formData.vehicle_model"
            clearable
            placeholder="车型"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in VEHICLE_MODEL"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.is_merge"
            clearable
            placeholder="是否合车"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="inputs">
          <el-select v-model="formData.status" clearable placeholder="物流状态">
            <el-option label="未开仓" :value="-1"></el-option>
            <el-option label="未进场" :value="0"></el-option>
            <el-option label="未发车" :value="1"></el-option>
            <el-option label="未到达" :value="2"></el-option>
            <el-option label="已到达" :value="3"></el-option>
          </el-select>
        </div>
        <!-- 物流车队二期 start -->
        <div class="inputs">
          <el-input
            v-model="formData.license_plates_no"
            clearable
            placeholder="车牌号"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.vehicle_model_type_name"
            clearable
            placeholder="车类别"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in carModelType"
              :key="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 物流车队二期 end -->
        <div class="inputs">
          <el-select
            v-model="formData.is_platform_dispatch"
            clearable
            placeholder="是否平台调车"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.is_platform_fleet"
            clearable
            placeholder="是否平台装卸"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.is_finish"
            clearable
            placeholder="城市仓是否分货完成"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">供货日期：</div>
          <el-date-picker
            v-model="cycle"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="供货时间始"
            end-placeholder="供货时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <div class="divname">进场时间：</div>
          <el-date-picker
            v-model="confirm_entry"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="进场时间始"
            end-placeholder="进场时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <div class="divname">发车时间：</div>
          <el-date-picker
            v-model="confirm_depart"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="发车时间始"
            end-placeholder="发车时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <div class="divname">到达时间：</div>
          <el-date-picker
            v-model="confirm_arrive"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="到达时间始"
            end-placeholder="到达时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <!-- 增加提现状态 end -->
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <!-- <el-button
            type="warning"
            @click="adddialog"
            icon="el-icon-circle-plus-outline"
            >添加</el-button
          > -->
          <el-button
            type="warning"
            :disabled="!tableData.length"
            @click="exportHandle"
            icon="el-icon-download"
            >导 出</el-button
          >
          <el-button
            :disabled="!tableData.length || disabledExport"
            @click="onHandleExport"
            type="danger"
            icon="el-icon-download"
            >导出车型</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
        </el-table-column>
        <el-table-column prop="delivery_name" align="center" label="自提点">
        </el-table-column>
        <el-table-column prop="is_merge" align="center" label="是否合车">
          <template slot-scope="scope">
            {{ scope.row.is_merge ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="fleet_name" align="center" label="队别">
        </el-table-column>
        <!-- 物流车队二期 start -->
        <el-table-column prop="car_list" align="center" label="车型">
          <template slot-scope="scope">
            <div
              v-for="(child, index) of scope.row.car_list
                .vehicle_model_name_list"
              :key="index"
            >
              {{ child }}
            </div>
            <div v-if="!scope.row.car_list.vehicle_model_name_list.length">
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="car_list" align="center" label="车类别">
          <template slot-scope="scope">
            <div
              v-for="(child, index) of scope.row.car_list
                .vehicle_model_type_name_list"
              :key="index"
            >
              {{ child }}
            </div>
            <div v-if="!scope.row.car_list.vehicle_model_type_name_list.length">
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="car_list" align="center" label="车牌(车头)">
          <template slot-scope="scope">
            <div
              v-for="(child, index) of scope.row.car_list
                .front_license_plates_no_list"
              :key="index"
            >
              {{ child }}
            </div>
            <div v-if="!scope.row.car_list.front_license_plates_no_list.length">
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="car_list" align="center" label="车牌(车尾)">
          <template slot-scope="scope">
            <div
              v-for="(child, index) of scope.row.car_list
                .after_license_plates_no_list"
              :key="index"
            >
              {{ child }}
            </div>
            <div v-if="!scope.row.car_list.after_license_plates_no_list.length">
              -
            </div>
          </template>
        </el-table-column>
        <!-- 物流车队二期 end -->
        <el-table-column
          prop="is_platform_fleet"
          align="center"
          label="是否平台装卸"
        >
          <template slot-scope="scope">
            {{ scope.row.is_platform_fleet ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="is_platform_dispatch"
          align="center"
          label="是否平台调车"
        >
          <template slot-scope="scope">
            {{ scope.row.is_platform_dispatch ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="cycle" align="center" label="供货日期">
        </el-table-column>
        <el-table-column
          prop="confirm_entry_at"
          align="center"
          label="进场时间"
        >
          <template slot-scope="scope">
            {{ scope.row.confirm_entry_at || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="confirm_depart_at"
          align="center"
          label="发车时间"
        >
          <template slot-scope="scope">
            {{ scope.row.confirm_depart_at || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="confirm_arrive_at"
          align="center"
          label="到达时间"
        >
          <template slot-scope="scope">
            {{ scope.row.confirm_arrive_at || "-" }}
          </template>
        </el-table-column>
        <!-- 物流车队二期 start -->
        <el-table-column
          prop="notes"
          align="center"
          label="备注"
          show-overflow-tooltip
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.notes || "-" }}
          </template>
        </el-table-column>
        <!-- 物流车队二期 end -->
        <el-table-column
          prop="status"
          align="center"
          label="物流状态"
          min-width="100"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              plain
              v-if="scope.row.status == -1"
              >未开仓</el-button
            >
            <el-button
              size="mini"
              type="warning"
              plain
              v-if="scope.row.status == 0"
              >未进场</el-button
            >
            <el-button
              size="mini"
              type="warning"
              plain
              v-else-if="scope.row.status == 1"
              >未发车</el-button
            >
            <el-button
              size="mini"
              type="warning"
              plain
              v-else-if="scope.row.status == 2"
              >未到达</el-button
            >
            <el-button
              size="mini"
              type="primary"
              plain
              v-else-if="scope.row.status == 3"
              >已到达</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="is_finish"
          align="center"
          label="城市仓是否分货完成"
        >
          <template slot-scope="scope">
            {{ scope.row.is_finish ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column prop="qc_status" align="center" label="发车情况">
          <template slot-scope="scope">
            <el-button type="text" @click="onHandleView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="onHandleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :center="true"
      v-if="personVisible"
      @close="personVisible = false"
      :visible.sync="personVisible"
      :title="personTitle"
      :append-to-body="true"
    >
      <div style="width: 80%" class="car-dialog-wrap">
        <el-form
          :model="personForm"
          :rules="formRules"
          ref="ruleForm"
          label-width="20%"
          class="demo-ruleForm from-wraps"
        >
          <el-form-item label="城市仓">
            <el-input
              v-model="personForm.logistics_name"
              :disabled="true"
              placeholder="请输入城市仓"
            ></el-input>
          </el-form-item>
          <el-form-item label="自提点">
            <el-input
              v-model="personForm.delivery_name"
              :disabled="true"
              placeholder="请输入自提点"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否合车" prop="is_merge_car">
            <el-select
              v-model="personForm.is_merge_car"
              clearable
              placeholder="是否合车"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in whetheroption"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="选择城市仓"
            prop="logistics_ids"
            v-if="personForm.is_merge_car == 1"
          >
            <el-select
              filterable
              v-model="personForm.logistics_ids"
              multiple
              clearable
              @change="onChangeCityStoreDialog"
              placeholder="选择城市仓"
            >
              <el-option
                v-for="item of cityStoreList"
                :key="item.id"
                :disabled="item.id == personForm.logistics_id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="选择自提点"
            prop="delivery_ids"
            v-if="personForm.is_merge_car === 1"
          >
            <el-select
              filterable
              v-model="personForm.delivery_ids"
              @change="onChangeDeliveryDialog"
              multiple
              clearable
              placeholder="选择自提点"
            >
              <!-- :disabled="child.id === personForm.delivery_id" -->
              <el-option
                v-for="child of dialogDeliveryList"
                :key="child.id"
                :label="child.name"
                :value="child.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="队别" prop="fleet_id">
            <el-select
              v-model="personForm.fleet_id"
              @change="changeFleet"
              style="width: 100%"
              placeholder="请选择队别"
            >
              <el-option
                v-for="item of FleetList"
                :key="item.id"
                :label="item.fleet_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!-- 物流车队二期 start -->
          <div class="car-list-wrap">
            <div
              class="car-child"
              v-for="(child, index) of personForm.car_list"
              :key="index"
            >
              <div class="handle">
                <i
                  class="icon el-icon-plus"
                  v-if="
                    index === personForm.car_list.length - 1 &&
                    personForm.car_list.length < CAR_MAX_LENGTH
                  "
                  @click="onAddCarData"
                ></i>
                <i
                  class="icon el-icon-delete"
                  v-if="personForm.car_list.length > 1"
                  @click="onDeleteCar(index)"
                ></i>
              </div>
              <el-form-item label="车型" prop="car_list">
                <el-select
                  v-model="child.vehicle_model_name"
                  clearable
                  placeholder="选择车型"
                >
                  <el-option
                    v-for="(item, index) in VEHICLE_MODEL"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车类别" prop="car_list">
                <el-select
                  v-model="child.vehicle_model_type_name"
                  clearable
                  placeholder="选择车类别"
                >
                  <el-option
                    v-for="(item, index) in carModelType"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车牌(车头)" prop="car_list">
                <el-input
                  v-model="child.front_license_plates_no"
                  clearable
                  placeholder="车牌(车头)"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="车牌(车尾)" prop="car_list">
                <el-input
                  v-model="child.after_license_plates_no"
                  clearable
                  placeholder="车牌(车尾)"
                >
                </el-input>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="用板数量" prop="use_plate_num">
            <el-input
              v-model="personForm.use_plate_num"
              clearable
              placeholder="输入用板数量"
            >
            </el-input>
          </el-form-item>
          <!-- 物流车队二期 end -->
          <el-form-item label="是否平台装卸" prop="is_platform_fleet">
            <el-select
              v-model="personForm.is_platform_fleet"
              clearable
              placeholder="是否平台装卸"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in whetheroption"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否平台调车" prop="is_platform_dispatch">
            <el-select
              v-model="personForm.is_platform_dispatch"
              clearable
              placeholder="是否平台调车"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in whetheroption"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流状态" prop="status">
            <el-select
              v-model="personForm.status"
              :disabled="disabledStatus"
              clearable
              placeholder="物流状态"
            >
              <el-option
                label="未开仓"
                :value="-1"
                :disabled="disabledStatus"
              ></el-option>
              <el-option
                label="未进场"
                :value="0"
                :disabled="disabledStatus"
              ></el-option>
              <el-option
                label="未发车"
                :value="1"
                :disabled="disabledStatus"
              ></el-option>
              <el-option
                label="未到达"
                :value="2"
                :disabled="disabledStatus"
              ></el-option>
              <el-option
                label="已到达"
                :value="3"
                :disabled="disabledStatus"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="personVisible = false">取消</el-button>
        <el-button type="primary" @click="personSure">保存</el-button>
      </template>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog
      :center="true"
      v-if="seepersonVisible"
      @close="seepersonVisible = false"
      :visible.sync="seepersonVisible"
      title="发车情况"
      :append-to-body="true"
    >
      <div>
        <div class="imglist">
          <div class="imglist-left">发车图片：</div>
          <div class="imglist-right">
            <el-image
              class="imgs"
              :src="item"
              fit="cover "
              v-for="(item, index) in srcList"
              :key="index"
              :initial-index="index"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
        <div class="imglist">
          <div class="imglist-left">发车视频：</div>
          <div class="imglist-right">
            <video
              v-for="(item, index) in videolist"
              :key="index"
              ref="videoPlayer"
              :src="item"
              :autoplay="false"
              :controls="true"
              class="video pointer"
            ></video>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="seepersonVisible = false">关闭</el-button>
      </template>
    </el-dialog>

    <!-- 查看弹框 -->
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { BASE } from "@/api";
import { VEHICLE_MODEL } from "@/utils/enum/app";
import { CAR_TYPE_MAP } from "@/views/logistics/utils/enum";
import { validInteger } from "@/utils/tools/validate";
import { formatDate } from "@/utils/tools/date.js";
import { getStoreFleetLogisticForCarExport } from "@/api/logisticsControl/car-logistics";

export default {
  name: "departure-situation",
  components: {},
  data() {
    return {
      loading: false,
      disabledExport: false, // 导出禁用
      VEHICLE_MODEL,
      cityStoreList: [], // 城市仓列表
      dialogDeliveryList: [], // 自提点列表
      selfTakeList: [], // 自提点列表
      carModelType: CAR_TYPE_MAP, // 车类别下拉
      CAR_MAX_LENGTH: 5, // 最多添加5辆车
      formData: {
        page: 1, //	number	页码
        pageSize: 10, //	number	页大小
        logistics_id: "", //	number	筛选城市仓id
        delivery_id: "", //	number	筛选自提点id
        cycle_start_time: formatDate(), //	string	筛选供货开始日期
        cycle_end_time: formatDate(), //	string	筛选供货结束日期
        confirm_entry_start_time: "", //	string	筛选进场开始日期
        confirm_entry_end_time: "", //	string	筛选进场结束日期
        vehicle_model: "", //	string	筛选车型
        is_merge: "", //	number	筛选是否合车 0=否 1=是
        confirm_depart_start_time: "", //	string	筛选发车开始日期
        confirm_depart_end_time: "", //	string	筛选发车结束日期
        confirm_arrive_start_time: "", //	string	筛选到达结束日期
        confirm_arrive_end_time: "", //	string	筛选到达结束日期
        status: "", //	number	筛选状态 0=未进场 1=未发车 2=未到达 3=已到达
        is_platform_fleet: "", //	number	筛选是否平台调车 0=否 1=是
        is_platform_dispatch: "", //	number	筛选是否平台装卸 0=否 1=是
        is_finish: "", //	number	筛选是否分货完成 0=否 1=是
        license_plates_no: "", // 车牌号
        vehicle_model_type_name: "", // 车类别
      }, // 表单参数

      cycle: [formatDate(), formatDate()],
      confirm_entry: "",
      confirm_depart: "",
      confirm_arrive: "",
      FleetList: [],
      srcList: [],
      videolist: [],
      personTitle: "",
      personbtn: "",
      personVisible: false,
      seepersonVisible: false,
      disabledStatus: false, // 是否禁用物流状态选项
      personForm: {
        id: "", //	number	记录id
        is_merge_car: "", //	number	必须是否合车 0=否 1=是
        logistics_id: "",
        delivery_id: "",
        logistics_ids: [], //	number []	必须城市仓id集合	item 类型: number
        delivery_ids: [], //	number []	必须自提点id集合item 类型: number
        fleet_id: "", // 队别
        fleet_name: "", // 队别名称
        // vehicle_model: [], //string []	车型集item 类型: string
        is_platform_fleet: "", //	number	是否平台装卸 0=否 1=是
        is_platform_dispatch: "", //	number	是否平台调度 0=否 1=是
        status: "", //	number	0=未进场 1=未发车 2=未到达 3=已到达
        car_list: [], // 车辆信息列表
        use_plate_num: "", // 用板数量
      },
      whetheroption: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      formRules: {
        use_plate_num: [
          {
            required: false,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback();
              }
              if (validInteger(value)) {
                callback(new Error("数量为大于0的整数！"));
              }
              callback();
            },
          },
        ],
      },
      tableData: [], // 城市仓列表
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    onInitData() {
      this.getAjaxCityStoreList();
      this.hqlist();
      this.hqgetLogisticList();
    },
    /**
     * 导出车型
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        // 组合需要的参数
        const query = {
          ...this.formData,
        };
        await getStoreFleetLogisticForCarExport(query);
      } catch (err) {
        console.log("ajax getStoreFleetLogisticForCarExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 修改了队别
     */
    changeFleet(data) {
      const findData = this.FleetList.find((child) => child.id === data);
      this.personForm.fleet_name = findData.fleet_name;
      // console.log("🆒 changeFleet", data);
    },
    hqgetLogisticList() {
      this.$api.logistics
        .getFleetList({
          page: 1,
          status: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.FleetList = res.data.data;
        });
    },
    /**
     * 城市仓发生变化
     */
    async onChangeCityStoreDialog(data) {
      // 重置自提点
      this.dialogDeliveryList = [];
      this.personForm.delivery_ids = [];
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeListDialog(params);
    },
    /**
     * 修改了自提点
     */
    onChangeDeliveryDialog(data) {
      // this.personForm.delivery_ids = data;
      console.log("mergeDeliveryList 🆒 mergeDeliveryList 6666666", data);
    },
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeListDialog: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId) {
        return;
      }
      const params = {
        name,
        logistics_id: logisticsId || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.dialogDeliveryList = data.data;
        console.log("ajx getAjaxSelfTakeList 666666", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    500),

    /**
     * 发车情况
     */
    onHandleView(row) {
      this.$api.logistics
        .getStoreFleetLogisticInfo({ id: row.id })
        .then((res) => {
          this.seepersonVisible = true;
          this.srcList = res.data.fleet_info_image
            ? res.data.fleet_info_image
            : [];
          this.videolist = res.data.fleet_info_video
            ? res.data.fleet_info_video
            : [];
        });

      // });
    },
    /**
     * 编辑
     */
    async onHandleEdit(row) {
      this.personTitle = "编辑";
      this.personbtn = "edit";
      const params = { id: row.id };
      const { data } = await this.$api.logistics.getStoreFleetLogisticInfo(
        params
      );
      const lastDataInfo = {
        id: data.id,
        is_merge_car: data.is_merge,
        logistics_name: data.logistics_name,
        delivery_name: data.delivery_name,
        fleet_id: data.fleet_id || "",
        fleet_name: data.fleet_name,
        use_plate_num: data.use_plate_num || "",
        logistics_id: data.logistics_id,
        delivery_id: data.delivery_id,
        is_platform_fleet: data.is_platform_fleet,
        is_platform_dispatch: data.is_platform_dispatch,
        status: data.status,
        car_list: data.car_list,
        logistics_ids: [],
        delivery_ids: [],
      };
      // 未开仓、已到达 不可修改
      this.disabledStatus = [3].includes(data.status);
      // lastDataInfo.logistics_ids = [data.logistics_id];
      // lastDataInfo.delivery_ids = [data.delivery_id];
      // 展示城市仓、自提点
      lastDataInfo.logistics_ids =
        data?.merge_logistics_list?.map((child) => child.logistics_id) || [];
      const mergeDeliveryList =
        data?.merge_delivery_list?.map((child) => child.delivery_id) || [];
      lastDataInfo.delivery_ids = mergeDeliveryList;
      const paramsData = {
        name: "",
        logisticsId: lastDataInfo.logistics_ids,
      };
      this.getAjaxSelfTakeListDialog(paramsData);
      this.personForm = lastDataInfo;
      /** 如果无车辆列，则新增一个 */
      if (!data.car_list.length) {
        this.onAddCarData();
      }
      console.log("mergeDeliveryList 🆒 mergeDeliveryList", lastDataInfo);
      this.personVisible = true;
    },
    /**
     * 加车
     */
    onAddCarData() {
      if (this.personForm.car_list.length >= this.CAR_MAX_LENGTH) {
        uni.showToast({
          title: `最多允许${this.CAR_MAX_LENGTH}辆车~`,
          icon: "none",
        });
        return;
      }

      const params = {
        vehicle_model_name: "",
        vehicle_model_type_name: "",
        front_license_plates_no: "",
        after_license_plates_no: "",
      };
      this.personForm.car_list.push(params);
    },
    /**
     * 减车
     */
    onDeleteCar(index) {
      this.personForm.car_list.splice(index, 1);
    },
    /**
     * 编辑点击保存
     */
    personSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.personForm);
          const {
            id,
            is_merge_car,
            logistics_ids,
            delivery_ids,
            fleet_id,
            fleet_name,
            is_platform_fleet,
            is_platform_dispatch,
            logistics_id,
            delivery_id,
            status,
            use_plate_num,
            car_list,
          } = this.personForm;
          const params = {
            id,
            logistics_id,
            delivery_id,
            is_merge_car,
            fleet_id,
            fleet_name,
            logistics_ids,
            delivery_ids,
            is_platform_fleet,
            is_platform_dispatch,
            status,
            use_plate_num,
          };
          if (!logistics_ids.includes(logistics_id)) {
            params.logistics_ids.push(logistics_id);
          }
          if (!delivery_ids.includes(delivery_id)) {
            params.delivery_ids.push(delivery_id);
          }
          // 遍历数组，只要其中一项有数据，则返回
          const carList = car_list.filter((child) => {
            let marker = false;
            Object.keys(child).forEach((_key) => {
              if (child[_key]) {
                marker = true;
              }
            });
            return marker;
          });
          const lastCarList = carList.map((child) => {
            const {
              vehicle_model_name,
              vehicle_model_type_name,
              front_license_plates_no,
              after_license_plates_no,
            } = child;
            return {
              vehicle_model_name,
              vehicle_model_type_name,
              front_license_plates_no,
              after_license_plates_no,
            };
          });
          params.car_list = lastCarList;
          this.$api.logistics.updateStoreFleetLogistic(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.personVisible = false;
            this.personbtn = "";
            this.hqlist();
          });
        }
      });
    },

    hqtime() {
      if (this.cycle != null) {
        if (this.cycle.length > 0) {
          this.formData.cycle_start_time = this.cycle[0];
          this.formData.cycle_end_time = this.cycle[1];
        } else {
          this.formData.cycle_start_time = "";
          this.formData.cycle_end_time = "";
        }
      } else {
        this.formData.cycle_start_time = "";
        this.formData.cycle_end_time = "";
      }
      if (this.confirm_entry != null) {
        if (this.confirm_entry.length > 0) {
          this.formData.confirm_entry_start_time = this.confirm_entry[0];
          this.formData.confirm_entry_end_time = this.confirm_entry[1];
        } else {
          this.formData.confirm_entry_start_time = "";
          this.formData.confirm_entry_end_time = "";
        }
      } else {
        this.formData.confirm_entry_start_time = "";
        this.formData.confirm_entry_end_time = "";
      }
      if (this.confirm_depart != null) {
        if (this.confirm_depart.length > 0) {
          this.formData.confirm_depart_start_time = this.confirm_depart[0];
          this.formData.confirm_depart_end_time = this.confirm_depart[1];
        } else {
          this.formData.confirm_depart_start_time = "";
          this.formData.confirm_depart_end_time = "";
        }
      } else {
        this.formData.confirm_depart_start_time = "";
        this.formData.confirm_depart_end_time = "";
      }
      if (this.confirm_arrive != null) {
        if (this.confirm_arrive.length > 0) {
          this.formData.confirm_arrive_start_time = this.confirm_arrive[0];
          this.formData.confirm_arrive_end_time = this.confirm_arrive[1];
        } else {
          this.formData.confirm_arrive_start_time = "";
          this.formData.confirm_arrive_end_time = "";
        }
      } else {
        this.formData.confirm_arrive_start_time = "";
        this.formData.confirm_arrive_end_time = "";
      }
    },
    hqlist() {
      this.hqtime();
      this.loading = true;
      this.$api.logistics
        .getStoreFleetLogisticList(this.formData)
        .then((res) => {
          console.log(res, "自采品列表数据");
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        });
    },

    exportHandle() {
      this.hqtime();
      let url = `${
        BASE.PRO2
      }/logistic/exportStoreFleetLogistic?token=${sessionStorage.getItem(
        "token"
      )}`;
      for (let key in this.formData) {
        if (key != "page" && key != "pageSize") {
          url = url + `&${key}=${this.formData[key]}`;
        }
      }
      window.open(url, "_blank");
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.cycle = [formatDate(), formatDate()];
      this.confirm_entry = [];
      this.confirm_depart = [];
      this.confirm_arrive = [];
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },

    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },

    /*
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      this.selfTakeList = []; // 重置自提点
      this.formData.delivery_id = ""; // 重置自提点搜索
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeList(params);
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.getAjaxCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.formData.logistics_id,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.getAjaxCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.formData.logistics_id,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 获取城市仓列表
     */
    getAjaxCityStoreList: debounce(async function (data) {
      const params = {
        source: 2,
      };
      try {
        const { data } = await this.$api.logistics.getLogisticList(params);
        this.cityStoreList = data;
        console.log("ajx getAjaxCityStoreList", data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    }, 500),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId || !this.formData.logistics_id) {
        return;
      }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    500),
  },
};
</script>
<style lang="scss">
.position {
  position: relative;
}
.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}
.position-subset {
  position: absolute;
  right: -20%;
  top: 38%;
  .el-icon-circle-plus {
    font-size: 45px;
  }
  .el-icon-delete-solid {
    font-size: 45px;
  }
}
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
        .divname {
          width: 100px;
        }
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
.imglist {
  display: flex;
  .imglist-left {
    margin-right: 20px;
    font-size: 18px;
  }
  .imglist-right {
    flex: 1;

    .imgs {
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }
    .video {
      width: 200px;
      height: 200px;
      margin-right: 20px;
    }
  }
}
/** 车辆信息容器 */
.car-dialog-wrap {
  height: 60vh;
  overflow-y: auto;
}
/** 车辆列表 */
.car-list-wrap {
  .car-child {
    position: relative;
    padding-right: 80px;
    box-sizing: border-box;
    .handle {
      position: absolute;
      top: 10px;
      right: 0;
      .icon {
        margin-right: 15px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
</style>
