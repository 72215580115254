// import axios from "@/api/http";
import { Message } from 'element-ui';
import url from "url";

const BASE_URL = process.env.VUE_APP_LOGISTICS_URL;

/**
 * 集配后台-装卸管理-装卸队管理-列表-导出
 * @param {Object} params
 */
export const getLogisticFleetListExport = (params = {}) => {
  let path = `${BASE_URL}/logistic/exportFleetList`;
  let token = sessionStorage.getItem("token");
  if (!token) {
    Message.error("账号登录异常");
    return;
  }
  const query = { ...params, token };
  delete query.page;
  delete query.pageSize;
  const queryData = url.format({ query });
  // console.log('🆒 getLogisticFleetListExport', path, queryData);
  window.open(path + queryData);
};

/**
 * 集配后台-发车情况-列表-导出车型
 * @param {Object} params
 */
export const getStoreFleetLogisticForCarExport = (params = {}) => {
  let path = `${BASE_URL}/logistic/exportStoreFleetLogisticForCar`;
  let token = sessionStorage.getItem("token");
  if (!token) {
    Message.error("账号登录异常");
    return;
  }
  const query = { ...params, token };
  delete query.page;
  delete query.pageSize;
  const queryData = url.format({ query });
  console.log('🆒 getStoreFleetLogisticForCarExport', path, queryData);
  window.open(path + queryData);
};
