/** 复核状态枚举 */
export const QC_STATUS_ENUM = {
  todo: {
    key: "todo",
    value: 1,
    label: "待复核",
  },
  has: {
    key: "has",
    value: 2,
    label: "已确认的缺货数",
  },
  hasDo: {
    key: "hasDo",
    value: 3,
    label: "已复核",
  },
  return: {
    key: "return",
    value: 4,
    label: "整单退回",
  },
};

/** 是否可重新复核枚举 */
export const QC_HANDLE_ENUM = {
  isHandle: {
    key: "isHandle",
    value: 0,
    label: "可重新复核",
  },
  unHandle: {
    key: "unHandle",
    value: 1,
    label: "不可重新复核",
  },
};

/** 品控复核 是否缺货 */
export const OUT_STOCK_ENUM = {
  no: {
    key: "no",
    value: 0,
    label: "否",
  },
  yes: {
    key: "yes",
    value: 1,
    label: "是",
  },
  default: {
    key: "default",
    value: 2,
    label: "-",
  },
};

/** 品控复核 是否品控退回 */
export const IS_RETURN_ENUM = {
  no: {
    key: "all",
    value: 1,
    label: "否",
  },
  yes: {
    key: "yes",
    value: 2,
    label: "是",
  },
  default: {
    key: "default",
    value: 0,
    label: "-",
  },
};

/** 品控状态 未品控 已品控 */
export const REVIEW_STATUS_ENUM = {
  no: {
    key: "no",
    value: 1,
    label: "未品控",
  },
  yes: {
    key: "yes",
    value: 2,
    label: "已品控",
  },
  timeOut: {
    key: "timeOut",
    value: 3,
    label: "品控超时",
  },
  notYetPassed: {
    key: "notYetPassed",
    value: 4,
    label: "未过品控",
  },
};

/** 复核状态 */
export const IS_CHECK_ENUM = {
  no: {
    key: "no",
    value: 1,
    label: "未复核",
  },
  yes: {
    key: "yes",
    value: 2,
    label: "已复核",
  },
  center: {
    key: "center",
    value: 3,
    label: "复核中",
  },
};

/** 缺货是否可以编辑 */
export const EDIT_STATUS_MAP = {
  readonly: {
    value: 0,
    label: "只读",
  },
  edit: {
    value: 1,
    label: "可编辑",
  },
}

/** 发车缺货原因 */
export const OUT_STOCK_REASON_ENUM = {
  QC_OUT_STOCK: {
    value: 5,
    label: "品控退回",
  },
  OUT_STOCK: {
    value: 6,
    label: "退货",
  },
  OTHER: {
    value: 99,
    label: "其他",
  }
}

/**
 * 车类别，目前前端写死（待后续接口完善）
 */
export const CAR_TYPE_MAP = [
  {
    value: '铁皮车',
    label: '铁皮车'
  },
  {
    value: '冷藏车',
    label: '冷藏车'
  },
  {
    value: '高栏车',
    label: '高栏车'
  },
]
